import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export type Question = {
  question: string;
  rating: number;
  order: number;
};
export class Questionnaire {
  constructor(
    public title: string,
    public type: string,
    public userRole: string,
    public questions: Question[],
    public trainings: {
      id: string;
      activeUntil?: string;
    }[],
    public trainingIds: string[],
    public isPublished: boolean,
    public trainingCenterId?: string,
    public id?: string,
    public sendingDate?: string
  ) {}

  static fromObject(data: any): Questionnaire {
    return new Questionnaire(
      data?.title,
      data?.type,
      data?.userRole,
      data?.questions ? data?.questions : [],
      data?.trainings ? data?.trainings : [],
      data?.trainingIds ? data?.trainingIds : [],
      data?.isPublished ? true : false,
      data?.trainingCenterId,
      data?.id
    );
  }

  static toObject(questionnaire: Questionnaire): any {
    const data = {
      title: questionnaire.title,
      type: questionnaire.type,
      userRole: questionnaire.userRole,
      questions: questionnaire.questions,
      trainings: questionnaire.trainings,
      trainingIds: questionnaire.trainings
        ? questionnaire.trainings.map((t) => t.id)
        : [],
      isPublished: questionnaire.isPublished,
      trainingCenterId: questionnaire.trainingCenterId,
    };

    return data;
  }

  static getDisplayFields(): any[] {
    const displayFields: any[] = [
      { name: 'title', header: 'Titre', size: 4 },
      {
        name: 'isPublished',
        header: 'Publié ?',
        transform: (val: boolean) => (val === true ? 'publié' : 'brouillon'),
        size: 2,
      },
    ];

    return displayFields;
  }

  static getQuestionDisplayFields(): any[] {
    const displayFields: any[] = [
      { name: 'order', header: '#', size: 1 },
      { name: 'question', header: 'Question', size: 8 },
      { name: 'rating', header: 'Note max.', size: 1 },
    ];

    return displayFields;
  }

  static getTrainingDisplayFields(): any[] {
    const displayFields: any[] = [
      { name: 'title', header: 'Titre', size: 4 },
      {
        name: 'activeUntil',
        header: `Réponses acceptées jusqu'au`,
        transform: (val: string) =>
          format(new Date(val), 'dd MMMM yyyy', { locale: fr }),
        type: 'date',
        size: 4,
      },
    ];

    return displayFields;
  }
}
