export const environment = {
  production: false,
  bundleId: 'fr.craf2s.app',
  url: 'https://test.admin.link-up.fr/',
  useEmulators: false,
  firebase: {
    projectId: 'craf2s-dev',
    appId: '1:383651297268:web:e5218e4f0b797a9b9b6ba1',
    storageBucket: 'craf2s-dev.appspot.com',
    apiKey: 'AIzaSyC9DXPc5FcbDbfeSLVHjzY9ODHV_CBtRgw',
    authDomain: 'craf2s-dev.firebaseapp.com',
    messagingSenderId: '383651297268',
  },
  dynamicLinkDomain: 'testcraf2s.page.link',
};
