import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AUTH_CONFIG_TOKEN,
  AUTH_PROVIDER_TOKEN,
  AuthConfig,
} from './auth.config';
import { AuthProvider } from './auth.provider';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;
  uid$: Observable<string>;

  constructor(
    @Inject(AUTH_CONFIG_TOKEN) private config: AuthConfig,
    @Inject(AUTH_PROVIDER_TOKEN) private authProvider: AuthProvider
  ) {
    this.authProvider.configure(this.config);
    this.uid$ = this.authProvider.uid$;
    this.isLoggedIn$ = this.uid$.pipe(map((uid) => !!uid));
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map((loggedIn) => !loggedIn));
  }

  getAuthenticated(): Observable<any> {
    return this.authProvider.getAuthenticated();
  }

  async login(email: string, password: string): Promise<any> {
    return await this.authProvider.signIn(email, password);
  }

  async registerUser(email: string, password: string): Promise<string> {
    return await this.authProvider.registerUser(email, password);
  }

  async sendForgotPassword(email: string) {
    await this.authProvider.sendForgotPassword(email);
  }

  async verifyResetPasswordCode(actionCode: string) {
    await this.authProvider.verifyResetPasswordCode(actionCode);
  }

  async resetPassword(actionCode: string, password: string) {
    await this.authProvider.resetPassword(actionCode, password);
  }

  async verifyEmail(actionCode: string) {
    await this.authProvider.verifyEmail(actionCode);
  }

  async logout() {
    console.log('Auth logout');
    await this.authProvider.signOut();
  }
}
