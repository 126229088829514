import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsubscriberService {
  subscribtions: { [service: string]: Subscription[] } = {};

  add(service: string, subscribtion: Subscription) {
    if (!this.subscribtions[service]) {
      this.subscribtions[service] = [subscribtion];
      return;
    }
    this.subscribtions[service].push(subscribtion);
  }

  unsubscribe(service: string) {
    if (!this.subscribtions[service]) {
      console.log('Unsubscribe unknown:', service);
      return;
    }

    this.subscribtions[service].forEach((subscription, index) => {
      console.log('unsubscribe ', service, index);
      subscription.unsubscribe();
    });
    delete this.subscribtions[service];
  }

  unsubscribeAll(except?: string[]) {
    console.log('unsubscribe ALL except?', except);
    for (const service in this.subscribtions) {
      if (except && except.includes(service)) {
        continue;
      }
      this.unsubscribe(service);
    }
  }
}
