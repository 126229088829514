import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'libs/global/global.service';
import { Observable, map, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { User } from 'libs/user/user.model';
import { UserService } from 'libs/user/user.service';
import { Training } from 'libs/training/training.model';
import { TrainingService } from 'libs/training/training.service';
import { Questionnaire } from 'libs/questionnaire/questionnaire.model';
import { QuestionnaireService } from 'libs/questionnaire/questionnaire.service';
import { TrainingCenter } from 'libs/training-center/training-center.model';
import { TrainingCenterService } from 'libs/training-center/training-center.service';

type AppPage = {
  title: string;
  url: string;
  icon: string;
  allLabel?: string;
  expanded?: boolean;
  groups?: Record<string, string>;
  children$?: Observable<any[]> | Record<string, Observable<any[]>>;
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser$: Observable<User | null> = this.userService.currentUser$;
  trainingCenter$: Observable<TrainingCenter | null> =
    this.userService.currentUser$.pipe(
      switchMap((user) =>
        user
          ? this.trainingCenterService.getCurrentUserTrainingCenter(
              user.trainingCenterId
            )
          : of(null)
      )
    );
  trainings$: Observable<Training[]> = this.userService.currentUser$.pipe(
    switchMap((user) =>
      user
        ? this.trainingService.getAllTrainings(user.trainingCenterId)
        : of([])
    )
  );
  questionnaires$: Observable<Questionnaire[]> =
    this.userService.currentUser$.pipe(
      switchMap((user) =>
        user
          ? this.questionnaireService.getAllQuestionnaires(
              user.trainingCenterId
            )
          : of([])
      )
    );

  appPages: AppPage[] = [
    { title: 'Tableau de bord', url: '/dashboard', icon: 'bar-chart' },
    {
      title: 'Formations',
      url: '/trainings',
      icon: 'people',
      allLabel: 'Toutes les formations',
      expanded: true,
      groups: {
        actives: 'Actives',
        nonActives: 'Non Actives',
        archived: 'Archivées',
      },
      children$: {
        actives: this.trainings$.pipe(
          map((trainings) => trainings.filter((training) => training.isActive))
        ),
        nonActives: this.trainings$.pipe(
          map((trainings) =>
            trainings.filter(
              (training) => !training.isActive && !training.isArchived
            )
          )
        ),
        archived: this.trainings$.pipe(
          map((trainings) =>
            trainings.filter(
              (training) => !training.isActive && training.isArchived
            )
          )
        ),
      },
    },
    {
      title: 'Évaluations stagiares',
      url: '/questionnaires/evaluation/apprentice',
      icon: 'stats-chart',
      allLabel: 'Toutes les évaluations',
      expanded: false,
      children$: this.questionnaires$.pipe(
        map((questionnaires) =>
          questionnaires.filter(
            (questionnaire) =>
              questionnaire.type === 'evaluation' &&
              questionnaire.userRole === 'apprentice'
          )
        )
      ),
    },
    {
      title: 'Bilans stagiaires',
      url: '/questionnaires/result/apprentice',
      icon: 'star-half',
      allLabel: 'Toutes les bilans stagiaires',
      expanded: false,
      children$: this.questionnaires$.pipe(
        map((questionnaires) =>
          questionnaires.filter(
            (questionnaire) =>
              questionnaire.type === 'result' &&
              questionnaire.userRole === 'apprentice'
          )
        )
      ),
    },
    {
      title: 'Bilans tuteurs',
      url: '/questionnaires/result/tutor',
      icon: 'star-half',
      allLabel: 'Toutes les bilans tuteurs',
      expanded: false,
      children$: this.questionnaires$.pipe(
        map((questionnaires) =>
          questionnaires.filter(
            (questionnaire) =>
              questionnaire.type === 'result' &&
              questionnaire.userRole === 'tutor'
          )
        )
      ),
    },
    { title: 'Administrateurs', url: '/users', icon: 'person' },
  ];

  constructor(
    private globalService: GlobalService,
    private userService: UserService,
    private trainingService: TrainingService,
    private questionnaireService: QuestionnaireService,
    private trainingCenterService: TrainingCenterService
  ) {}

  async ngOnInit() {
    this.globalService.disableLogOnProd(environment.production);
  }

  async logout() {
    await this.userService.logout(true);
  }
}
