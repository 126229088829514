import { Document } from 'libs/document/document.model';
import { User } from 'libs/user/user.model';

export class Training {
  constructor(
    public title: string,
    public managers: string[],
    public apprentices: {
      id: string;
      tutors: string[];
    }[],
    public isActive: boolean,
    public id?: string,
    public promotion?: number,
    public isArchived?: boolean,
    public trainingCenterId?: string,
    public networkId?: string,
    public documents?: Document[],
    public users: {
      managers: { [id: string]: User };
      apprentices: { [id: string]: User };
      tutors: { [id: string]: User };
      alumnis: { [id: string]: User };
    } = {
      managers: {},
      apprentices: {},
      tutors: {},
      alumnis: {},
    }
  ) {}

  static fromObject(data: any): Training {
    // Add id to documents
    const documents = data?.documents
      ? data?.documents.map((doc: Document, index: number) => {
          doc.id = index.toString();
          return doc;
        })
      : [];
    return new Training(
      data?.title,
      data?.managers ? data?.managers : [],
      data?.apprentices ? data?.apprentices : [],
      data?.isActive ? true : false,
      data?.id,
      data?.promotion,
      data?.isArchived ? true : false,
      data?.trainingCenterId,
      data?.networkId,
      documents
    );
  }

  static toObject(training: Training): any {
    const data = {
      title: training.title,
      managers: training.managers,
      apprentices: training.apprentices,
      isActive: training.isActive,
    };

    if (training.id) {
      data['id'] = training.id;
    }

    if (training.promotion) {
      data['promotion'] = training.promotion;
    }

    if (training.isArchived) {
      data['isArchived'] = training.isArchived;
    }

    if (training.trainingCenterId) {
      data['trainingCenterId'] = training.trainingCenterId;
    }

    if (training.networkId) {
      data['networkId'] = training.networkId;
    }

    if (training.documents) {
      data['documents'] = training.documents;
    }

    return data;
  }

  static getDisplayFields(): any[] {
    const displayFields: any[] = [
      { name: 'title', header: 'Titre', size: 4 },
      {
        name: 'isActive',
        header: 'Active ?',
        transform: (val: boolean) => (val === true ? 'active' : 'non active'),
        size: 2,
      },
      {
        name: 'isArchived',
        header: 'Archivée ?',
        transform: (val: boolean) => (val === true ? 'archivée' : ''),
        size: 2,
        color: 'warning',
      },
    ];

    return displayFields;
  }
}
