import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { TrainingCenter } from './training-center.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { finalize, tap } from 'rxjs/operators';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { User } from 'libs/user/user.model';
import { Training } from 'libs/training/training.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingCenterService {
  private isListeningToTrainingCenterIds: string[] = [];
  private isListeningToAllTrainingCenters = false;
  private trainingCentersSubject: BehaviorSubject<TrainingCenter[]> =
    new BehaviorSubject([]);
  trainingCenters$: Observable<TrainingCenter[]> =
    this.trainingCentersSubject.asObservable();

  private isListeningToCurrentUserTrainingCenter = false;
  private currentUserTrainingCenterSubject: BehaviorSubject<TrainingCenter> =
    new BehaviorSubject(null);
  currentUserTrainingCenter$: Observable<TrainingCenter> =
    this.currentUserTrainingCenterSubject.asObservable();

  constructor(
    private apiService: FirebaseApiProvider,
    private unsubscriberService: UnsubscriberService
  ) {}

  getSuperAdminGuardTrainingCenter(
    trainingCenterId: string
  ): Observable<TrainingCenter | null> {
    const currentUserTrainingCenter =
      this.currentUserTrainingCenterSubject.getValue();
    if (currentUserTrainingCenter?.id === trainingCenterId) {
      console.log(
        'getSuperAdminGuardTrainingCenter FROM CACHE:',
        currentUserTrainingCenter
      );
      return this.currentUserTrainingCenter$;
    }

    // It runs only when the user logs in or on page refresh
    return from(
      this.apiService.fetchOne<TrainingCenter>(
        'trainingCenters',
        trainingCenterId,
        TrainingCenter.fromObject
      )
    ).pipe(
      tap((trainingCenter) => {
        console.log('getSuperAdminGuardTrainingCenter FETCH:', trainingCenter);
        if (trainingCenter) {
          this.currentUserTrainingCenterSubject.next(trainingCenter);
          this.listenToCurrentUserTrainingCenterChanges(trainingCenterId);
        }
      }),
      finalize(() =>
        console.log('getSuperAdminGuardTrainingCenter FETCH finalized.')
      )
    );
  }

  getCurrentUserTrainingCenter(
    trainingCenterId: string
  ): Observable<TrainingCenter> {
    if (!this.isListeningToCurrentUserTrainingCenter) {
      console.log('getCurrentUserTrainingCenter API CALL LISTENING');
      this.listenToCurrentUserTrainingCenterChanges(trainingCenterId);
    } else {
      console.log('getCurrentUserTrainingCenter FROM CACHE');
    }

    return this.currentUserTrainingCenter$;
  }

  private listenToCurrentUserTrainingCenterChanges(trainingCenterId: string) {
    this.isListeningToCurrentUserTrainingCenter = true;

    const trainingCenterSubscriber = this.apiService
      .listenToChanges<TrainingCenter>(
        'trainingCenters',
        TrainingCenter.fromObject,
        [
          {
            fieldPath: 'id',
            condition: '==',
            value: trainingCenterId,
          },
        ],
        'name'
      )
      .pipe(
        finalize(() => {
          this.isListeningToCurrentUserTrainingCenter = false;
          console.log('listenToCurrentUserTrainingCenterChanges finalized.');
        })
      )
      .subscribe((trainingCenters) => {
        console.log(
          'listenToCurrentUserTrainingCenterChanges NEXT:',
          trainingCenters
        );
        this.currentUserTrainingCenterSubject.next(trainingCenters[0]);
      });
    this.unsubscriberService.add(
      'TrainingCenterService:listenToCurrentUserTrainingCenterChanges',
      trainingCenterSubscriber
    );
  }

  getAllTrainingCenters(): Observable<TrainingCenter[]> {
    if (!this.isListeningToAllTrainingCenters) {
      console.log('getUserAllTrainingCenters API CALL LISTENING');
      this.listenToAllTrainingCenterChanges();
    } else {
      console.log('getUserAllTrainingCenters FROM CACHE');
    }

    return this.trainingCenters$;
  }

  private listenToAllTrainingCenterChanges() {
    this.isListeningToAllTrainingCenters = true;

    const trainingCentersSubscriber = this.apiService
      .listenToChanges<TrainingCenter>(
        'trainingCenters',
        TrainingCenter.fromObject,
        [],
        'name'
      )
      .pipe(
        finalize(() => {
          this.isListeningToAllTrainingCenters = false;
          console.log('listenToAllTrainingCenterChanges finalized.');
        })
      )
      .subscribe((trainingCenters) => {
        console.log('listenToAllTrainingCenterChanges NEXT:', trainingCenters);
        this.trainingCentersSubject.next(trainingCenters);
      });
    this.unsubscriberService.add(
      'TrainingCenterService:listenToAllTrainingCenterChanges',
      trainingCentersSubscriber
    );
  }

  async deleteTrainingCenter(trainingCenterId: string): Promise<void> {
    try {
      await this.apiService.delete('trainingCenters', trainingCenterId);
    } catch (e) {
      throw e;
    }
  }

  async createOrUpdateTrainingCenter(
    trainingCenter: TrainingCenter
  ): Promise<TrainingCenter> {
    try {
      if (!trainingCenter?.id || trainingCenter?.id === '') {
        console.log('create new trainingCenter: ', trainingCenter);

        if (await this.trainingCenterExists(trainingCenter)) {
          throw { code: 'create/trainingCenter-exists' };
        }

        trainingCenter.id = await this.apiService.create<TrainingCenter>(
          'trainingCenters',
          trainingCenter,
          TrainingCenter.toObject
        );
      } else {
        await this.apiService.update(
          `trainingCenters/${trainingCenter.id}`,
          trainingCenter,
          TrainingCenter.toObject
        );
      }

      return trainingCenter;
    } catch (e) {
      throw e;
    }
  }

  async trainingCenterExists(
    trainingCenter: TrainingCenter
  ): Promise<TrainingCenter | null> {
    const trainingCenters = await this.apiService.fetchAll<TrainingCenter>(
      'trainingCenters',
      TrainingCenter.fromObject,
      [
        {
          fieldPath: 'name',
          condition: '==',
          value: trainingCenter?.name,
        },
      ]
    );
    return trainingCenters?.length > 0 ? trainingCenters[0] : null;
  }

  async checkAndUpdateTrainingCenterLimits({
    trainingCenter,
    training,
    oldTraining,
    userToSave,
    usersToDelete,
  }: {
    trainingCenter: TrainingCenter;
    training: Training;
    oldTraining?: Training;
    userToSave?: User;
    usersToDelete?: User[];
  }): Promise<void> {
    // Check if user is not apprentice or is already associated to the training, if yes return
    if (
      userToSave &&
      (userToSave.role !== 'apprentice' ||
        training.apprentices.filter((a) => a.id === userToSave.id).length > 0)
    ) {
      return;
    }

    // Case Remove Users :
    if (usersToDelete?.length > 0 && training.isActive) {
      console.log(
        'Update Training Center Limits: Remove Users',
        usersToDelete?.length
      );

      trainingCenter.apprenticesCount -= usersToDelete?.length;
      await this.createOrUpdateTrainingCenter(trainingCenter);
      return;
    }

    // Cases Set Active / Non Active Training
    if (
      trainingCenter.currentSubscription?.limits?.maxApprentices !== undefined
    ) {
      // Training was not active
      if (!oldTraining || !oldTraining.isActive) {
        if (training?.isActive) {
          if (
            trainingCenter.apprenticesCount +
              (userToSave ? 1 : training.apprentices.length) >
            trainingCenter.currentSubscription.limits.maxApprentices
          ) {
            // eslint-disable-next-line no-throw-literal
            throw { code: 'create/max-apprentices-reached' };
          } else {
            trainingCenter.apprenticesCount += userToSave
              ? 1
              : training.apprentices.length;
            await this.createOrUpdateTrainingCenter(trainingCenter);
          }
        }
        // Training was active
      } else if (!training.isActive) {
        trainingCenter.apprenticesCount -= userToSave
          ? 1
          : training.apprentices.length;
        await this.createOrUpdateTrainingCenter(trainingCenter);
      }
    }
  }
}
