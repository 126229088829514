import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  filter,
  finalize,
  lastValueFrom,
  map,
  take,
} from 'rxjs';
import { Questionnaire } from './questionnaire.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { WhereQuery } from 'libs/api/api.provider';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  private questionnairesSubject = new BehaviorSubject<Questionnaire[]>([]);
  questionnaires$ = this.questionnairesSubject.asObservable();
  private isListeningToAllQuestionnaires = false;
  type: string;
  userRole: string;
  trainingId: string;

  constructor(
    private apiService: FirebaseApiProvider,
    private unsubscriberService: UnsubscriberService
  ) {}

  getAllQuestionnaires(trainingCenterId?: string) {
    console.log('getAllQuestionnaires:', trainingCenterId);
    console.log(
      'this.isListeningToAllQuestionnaires:',
      this.isListeningToAllQuestionnaires
    );
    if (!this.isListeningToAllQuestionnaires) {
      console.log('getAllQuestionnaires API CALL LISTENING');
      this.listenToAllQuestionnaireChanges(trainingCenterId);
    } else {
      console.log('getAllQuestionnaires FROM CACHE');
    }

    return this.questionnaires$;
  }

  getTrainingQuestionnaires(
    type: string,
    userRole: string,
    trainingId: string
  ) {
    if (
      this.type !== type ||
      this.userRole !== userRole ||
      this.trainingId !== trainingId
    ) {
      console.log(
        'getTrainingQuestionnaires API CALL LISTENING:',
        type,
        userRole,
        trainingId
      );
      this.listenToTrainingQuestionnaireChanges(type, userRole, trainingId);
    } else {
      console.log('getTrainingQuestionnaires FROM CACHE');
    }

    return this.questionnaires$;
  }

  private listenToTrainingQuestionnaireChanges(
    type: string,
    userRole: string,
    trainingId: string
  ) {
    if (this.questionnairesSubject.getValue()) {
      this.questionnairesSubject.next([]);
    }
    this.unsubscriberService.unsubscribe(
      'QuestionnaireService:listenToTrainingQuestionnaireChanges'
    );
    this.type = type;
    this.userRole = userRole;
    this.trainingId = trainingId;

    const questionnairesSubscriber = this.apiService
      .listenToChanges<Questionnaire>(
        'questionnaires',
        Questionnaire.fromObject,
        [
          {
            fieldPath: 'type',
            condition: '==',
            value: type,
          },
          {
            fieldPath: 'userRole',
            condition: '==',
            value: userRole,
          },
          {
            fieldPath: 'trainingIds',
            condition: 'array-contains',
            value: trainingId,
          },
          {
            fieldPath: 'isPublished',
            condition: '==',
            value: true,
          },
        ]
      )
      .pipe(
        finalize(() => {
          delete this.type;
          delete this.userRole;
          delete this.trainingId;
          console.log('listenToTrainingQuestionnaireChanges finalized.');
        })
      )
      .subscribe((questionnaires) => {
        console.log(
          'listenToTrainingQuestionnaireChanges NEXT:',
          questionnaires
        );
        this.questionnairesSubject.next(questionnaires);
      });
    this.unsubscriberService.add(
      'QuestionnaireService:listenToTrainingQuestionnaireChanges',
      questionnairesSubscriber
    );
  }

  private listenToAllQuestionnaireChanges(trainingCenterId?: string) {
    if (this.questionnairesSubject.getValue()) {
      this.questionnairesSubject.next([]);
    }
    this.unsubscriberService.unsubscribe(
      'QuestionnaireService:listenToTrainingQuestionnaireChanges'
    );
    this.isListeningToAllQuestionnaires = true;

    const whereQ: WhereQuery[] = [];
    if (trainingCenterId) {
      whereQ.push({
        fieldPath: 'trainingCenterId',
        condition: '==',
        value: trainingCenterId,
      });
    }

    const questionnairesSubscriber = this.apiService
      .listenToChanges<Questionnaire>(
        'questionnaires',
        Questionnaire.fromObject,
        whereQ,
        'title'
      )
      .pipe(
        finalize(() => {
          this.isListeningToAllQuestionnaires = false;
          console.log('listenToAllQuestionnaireChanges finalized.');
        })
      )
      .subscribe((questionnaires) => {
        console.log('listenToAllQuestionnaireChanges NEXT:', questionnaires);
        this.questionnairesSubject.next(questionnaires);
      });
    this.unsubscriberService.add(
      'QuestionnaireService:listenToAllQuestionnaireChanges',
      questionnairesSubscriber
    );
  }

  sortByActiveUntilOrSendingDate(
    questionnaires: Questionnaire[],
    trainingId: string
  ): Questionnaire[] {
    return questionnaires.sort((qa: any, qb: any) => {
      const sendingDateQa = qa.sendingDate;
      const activeUntilDateQa = qa.trainings.find(
        (trainings: { activeUntil: Date; id: string }) =>
          trainings.id === trainingId
      ).activeUntil;

      const sendingDateQb = qb.sendingDate;
      const activeUntilDateQb = qb.trainings.find(
        (trainings: { activeUntil: Date; id: string }) =>
          trainings.id === trainingId
      )?.activeUntil;

      if (sendingDateQa) {
        if (sendingDateQb) {
          if (sendingDateQa > sendingDateQb) {
            return -1;
          }
          if (sendingDateQa < sendingDateQb) {
            return 1;
          }
          return 0;
        }

        return 1;
      }

      if (sendingDateQb) {
        return -1;
      }

      if (activeUntilDateQa > activeUntilDateQb) {
        return 1;
      }
      if (activeUntilDateQa < activeUntilDateQb) {
        return -1;
      }
      return 0;
    });
  }

  async fetchTrainingQuestionnaires(
    trainingId: String
  ): Promise<Questionnaire[]> {
    return this.apiService.fetchAll<Questionnaire>(
      'questionnaires',
      Questionnaire.fromObject,
      [
        {
          fieldPath: 'trainingIds',
          condition: 'array-contains',
          value: trainingId,
        },
        {
          fieldPath: 'isPublished',
          condition: '==',
          value: true,
        },
      ]
    );
  }

  async createOrUpdateQuestionnaire(
    questionnaire: Questionnaire
  ): Promise<Questionnaire> {
    try {
      if (!questionnaire?.id || questionnaire?.id === '') {
        if (await this.questionnaireExists(questionnaire)) {
          throw { code: 'create/questionnaire-exists' };
        }

        questionnaire.id = await this.apiService.create(
          'questionnaires',
          questionnaire,
          Questionnaire.toObject
        );
      } else {
        await this.apiService.update(
          `questionnaires/${questionnaire.id}`,
          questionnaire,
          Questionnaire.toObject
        );
      }

      return questionnaire;
    } catch (e) {
      throw e;
    }
  }

  async deleteQuestionnaire(questionnaireId: string): Promise<void> {
    try {
      await this.apiService.delete('questionnaires', questionnaireId);
    } catch (e) {
      throw e;
    }
  }

  async questionnaireExists(
    questionnaire: Questionnaire
  ): Promise<Questionnaire | null> {
    const questionnaires = await this.apiService.fetchAll<Questionnaire>(
      'questionnaires',
      Questionnaire.fromObject,
      [
        {
          fieldPath: 'title',
          condition: '==',
          value: questionnaire?.title,
        },
        {
          fieldPath: 'type',
          condition: '==',
          value: questionnaire?.type,
        },
        {
          fieldPath: 'userRole',
          condition: '==',
          value: questionnaire?.userRole,
        },
      ]
    );

    return questionnaires?.length > 0 ? questionnaires[0] : null;
  }
}
