import { InjectionToken } from '@angular/core';
import { AuthProvider } from './auth.provider';

export const AUTH_CONFIG_TOKEN = new InjectionToken<AuthConfig>('Auth Configuration');

export type AuthConfig = {
  baseUrl: string;
  bundleId: string;
  dynamicLinkDomain: string;
};

export const AUTH_PROVIDER_TOKEN = new InjectionToken<AuthProvider>('Auth Provider');
