import { Injectable } from '@angular/core';
import { ApiProvider, WhereQuery } from '../api.provider';
import {
  Firestore,
  collection,
  query,
  where,
  QueryConstraint,
  orderBy,
  limit,
  doc,
  updateDoc,
  documentId,
  OrderByDirection,
  startAfter,
  WhereFilterOp,
  docData,
  collectionChanges,
  setDoc,
  addDoc,
  writeBatch,
  deleteDoc,
  getDocs,
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirebaseApiProvider implements ApiProvider {
  constructor(private firestore: Firestore, private functions: Functions) {
    functions.region = 'europe-west3';
  }

  fetchOne<T>(
    path: string,
    id: string,
    fromJson: (data: { [x: string]: any }) => T
  ): Observable<T | null> {
    const docRef = doc(this.firestore, `${path}/${id}`);

    return docData(docRef, { idField: 'id' }).pipe(map((doc) => fromJson(doc)));
  }

  async fetchAll<T>(
    path: string,
    fromJson: (data: { [x: string]: any }) => T,
    whereQuery?: WhereQuery[],
    order?: string,
    orderDirection?: OrderByDirection,
    limitCount?: number,
    startAfterValue?: any
  ): Promise<T[]> {
    const q = this._buildQuery(
      path,
      whereQuery,
      order,
      orderDirection,
      limitCount,
      startAfterValue
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) =>
      fromJson({ ...doc.data(), id: doc.id })
    ) as T[];
  }

  listenToChanges<T>(
    path: string,
    fromJson: (data: { [x: string]: any }) => T,
    whereQuery?: WhereQuery[],
    order?: string,
    orderDirection?: OrderByDirection,
    limitCount?: number,
    startAfterValue?: any
  ): Observable<T[]> {
    const cachedValues: T[] = [];
    const q = this._buildQuery(
      path,
      whereQuery,
      order,
      orderDirection,
      limitCount,
      startAfterValue
    );

    return collectionChanges(q).pipe(
      map((documentChanges) => {
        documentChanges.forEach((change) => {
          if (change.type === 'removed') {
            cachedValues.splice(change.oldIndex, 1);
            return;
          }
          cachedValues.splice(
            change.newIndex,
            change.oldIndex === change.newIndex ? 1 : 0,
            fromJson({ ...change.doc.data(), id: change.doc.id })
          );
        });

        return cachedValues;
      }),
      finalize(() => {
        console.log(
          `listenToChange ${path} finalized, emptying cachedValues...`
        );
        cachedValues.splice(0, cachedValues.length);
      })
    );
  }

  callFunction<T>(functionName: string, params: {}): Observable<T> {
    const func = httpsCallableData(this.functions, functionName);
    console.log('callFunction', params);
    return func(params) as Observable<T>;
  }

  update<T>(
    path: string,
    data: T,
    toJson: (object: T) => { [x: string]: any }
  ): Promise<void> {
    try {
      const docRef = doc(this.firestore, path);
      return updateDoc(docRef, toJson(data));
    } catch (e) {
      throw e;
    }
  }

  async updateBatch<T extends { id?: string }>(
    path: string,
    data: T[],
    toJson: (object: T) => { [x: string]: any }
  ): Promise<void> {
    try {
      const batch = writeBatch(this.firestore);
      data.forEach((d) => {
        const docRef = doc(this.firestore, `${path}/${d.id}`);
        batch.set(docRef, toJson(d));
      });
      await batch.commit();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async create<T>(
    path: string,
    data: T,
    toJson: (object: T) => { [x: string]: any },
    id?: string
  ): Promise<string> {
    try {
      if (id) {
        const docRef = doc(this.firestore, path, id);
        await setDoc(docRef, toJson(data));
        return id;
      }
      const collectionRef = collection(this.firestore, path);
      const docRef = await addDoc(collectionRef, toJson(data));
      return docRef.id;
    } catch (e) {
      throw e;
    }
  }

  async createBatch<T>(
    path: string,
    data: T[],
    toJson: (object: T) => { [x: string]: any }
  ): Promise<void> {
    try {
      const batch = writeBatch(this.firestore);
      data.forEach((d) => {
        const docRef = doc(collection(this.firestore, path));
        batch.set(docRef, toJson(d));
      });
      await batch.commit();
    } catch (e) {
      throw e;
    }
  }

  async delete(path: string, id: string): Promise<void> {
    try {
      const docRef = doc(this.firestore, `${path}/${id}`);
      await deleteDoc(docRef);
    } catch (e) {
      throw e;
    }
  }

  async deleteBatch(path: string, ids: string[]): Promise<void> {
    try {
      const batch = writeBatch(this.firestore);
      ids.forEach((id) => {
        const docRef = doc(this.firestore, `${path}/${id}`);
        batch.delete(docRef);
      });
      await batch.commit();
    } catch (e) {
      throw e;
    }
  }

  private _buildQuery(
    path: string,
    whereQuery?: WhereQuery[],
    order?: string,
    orderDirection?: OrderByDirection,
    limitCount?: number,
    startAfterValue?: any
  ) {
    const collectionRef = collection(this.firestore, path);
    const queryConstraints: QueryConstraint[] = [];

    if (whereQuery && whereQuery.length > 0) {
      whereQuery.forEach((whereQ) => {
        queryConstraints.push(
          where(
            whereQ.fieldPath === 'id' ? documentId() : whereQ.fieldPath,
            whereQ.condition as WhereFilterOp,
            whereQ.value
          )
        );
      });
    }

    if (order) {
      queryConstraints.push(orderBy(order, orderDirection));
    }

    if (limitCount) {
      queryConstraints.push(limit(limitCount));
    }

    if (startAfterValue) {
      queryConstraints.push(startAfter(startAfterValue));
    }

    return query(collectionRef, ...queryConstraints);
  }
}
