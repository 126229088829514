import { Injectable } from '@angular/core';
import {
  ActionCodeSettings,
  Auth,
  User,
  applyActionCode,
  authState,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  user,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { AuthProvider } from '../auth.provider';
import { AuthConfig } from '../auth.config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthProvider implements AuthProvider {
  // private recaptchaVerifier: RecaptchaVerifier;
  // private resolver: MultiFactorResolver;

  uid$ = authState(this.fireAuth).pipe(
    map((user) => (user?.emailVerified ? user.uid : null))
  );
  private actionCodeSettings: ActionCodeSettings;

  constructor(private fireAuth: Auth) {}

  configure(config: AuthConfig) {
    this.actionCodeSettings = {
      url: config.baseUrl,
      iOS: {
        bundleId: config.bundleId,
      },
      android: {
        packageName: config.bundleId,
      },
      handleCodeInApp: false,
      dynamicLinkDomain: config.dynamicLinkDomain,
    };
  }

  getAuthenticated(): Observable<User> {
    return user(this.fireAuth);
  }

  async signIn(email: string, password: string): Promise<any> {
    try {
      const signIngResult = await signInWithEmailAndPassword(
        this.fireAuth,
        email,
        password
      );
      console.log('SIGN IN RESULT:', signIngResult);
      if (signIngResult.user?.emailVerified === false) {
        await this.sendEmailVerification(signIngResult.user);
        throw { code: 'auth/unverified-email' };
      }
      return signIngResult;
    } catch (e) {
      console.error(e);
      // if (e.code == 'auth/multi-factor-auth-required') {
      //   this.resolver = getMultiFactorResolver(this.fireAuth, e);
      //   console.log('SignIn resolver: ', this.resolver);
      //   if (this.resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      //     return await this.sendSMSCode();
      //   } else {
      //     // Unsupported second factor.
      //   }

      //   return;
      // } else {
      throw e;
      // }
    }
  }

  async registerUser(email: string, password: string): Promise<string> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        this.fireAuth,
        email,
        password
      );
      await this.sendEmailVerification(userCredential.user);
      return userCredential.user.uid;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async sendForgotPassword(email: string) {
    try {
      const actionCodeSettings = { ...this.actionCodeSettings };
      actionCodeSettings.url += `login?email=${email}`;
      console.log('Auth SendForgotPassword: ', email, actionCodeSettings);
      await sendPasswordResetEmail(this.fireAuth, email, actionCodeSettings);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async verifyResetPasswordCode(actionCode: string) {
    try {
      await checkActionCode(this.fireAuth, actionCode);
      await verifyPasswordResetCode(this.fireAuth, actionCode);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async resetPassword(actionCode: string, newPassword: string) {
    try {
      await this.verifyResetPasswordCode(actionCode);
      await confirmPasswordReset(this.fireAuth, actionCode, newPassword);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async verifyEmail(actionCode: string) {
    try {
      await applyActionCode(this.fireAuth, actionCode);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async signOut(): Promise<any> {
    await signOut(this.fireAuth);
  }

  async sendEmailVerification(user: User): Promise<void> {
    const actionCodeSettings = { ...this.actionCodeSettings };
    actionCodeSettings.url += 'avatar';
    console.log('sendVerificationEmail:', user, actionCodeSettings);
    await sendEmailVerification(user, actionCodeSettings);
  }

  // checkAuth(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     onAuthStateChanged(this.fireAuth, (user) => {
  //       // console.log('auth user: ', user);
  //       resolve(user);
  //     });
  //   });
  // }

  // async updateAuthUserEmail(user: User) {
  //   try {
  //     await updateEmail(user, newEmail);
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // async sendSMSCode(user?: User, phoneNumber?: string) {
  //   try {
  //     this.createRecaptchaVerifier('reCaptcha');
  //     let phoneInfoOptions;
  //     if (!this.resolver && user && phoneNumber) {
  //       console.log('createMultiFactor :', user, phoneNumber);
  //       const multiFactorSession = await multiFactor(user).getSession();
  //       phoneInfoOptions = {
  //         phoneNumber: phoneNumber,
  //         session: multiFactorSession,
  //       };
  //     } else if (!phoneInfoOptions) {
  //       phoneInfoOptions = {
  //         multiFactorHint: this.resolver.hints[0],
  //         session: this.resolver.session,
  //       };
  //     }
  //     console.log('FB sendSMSCode: ', phoneInfoOptions);
  //     const phoneAuthProvider = new PhoneAuthProvider(this.fireAuth);
  //     console.log('FB sendSMSCode: ', phoneAuthProvider);
  //     const verificationId = await phoneAuthProvider.verifyPhoneNumber(
  //       phoneInfoOptions,
  //       this.recaptchaVerifier
  //     );

  //     console.log('FB sendSMSCode verificationId: ', verificationId);
  //     console.log(
  //       'FB sendSMSCode phoneNumber: ',
  //       phoneInfoOptions.phoneNumber
  //         ? phoneInfoOptions.phoneNumber
  //         : phoneInfoOptions.multiFactorHint.phoneNumber
  //     );

  //     return {
  //       verificationId,
  //       phoneNumber: phoneInfoOptions.phoneNumber
  //         ? phoneInfoOptions.phoneNumber
  //         : phoneInfoOptions.multiFactorHint.phoneNumber,
  //     };
  //   } catch (e) {
  //     console.log(e);
  //     throw e;
  //   }
  // }

  // async verifyCode(verificationId: string, verificationCode: string) {
  //   try {
  //     const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
  //     const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  //     console.log('multiFactorAssertion: ', multiFactorAssertion);
  //     const user = getAuth().currentUser;
  //     console.log('verifyCode FB authUser: ', user);
  //     if (!this.resolver) {
  //       await multiFactor(user).enroll(multiFactorAssertion);
  //       return user;
  //     }
  //     return (await this.resolver.resolveSignIn(multiFactorAssertion)).user;
  //   } catch (e) {
  //     console.error(e);
  //     throw e;
  //   }
  // }

  // createRecaptchaVerifier(elementId: string) {
  //   this.recaptchaVerifier = new RecaptchaVerifier(
  //     elementId,
  //     {
  //       'size': 'invisible',
  //       // callback: (response) => {
  //       //   console.log('createRecaptchaVerifier response: ', response);
  //       // },
  //       // 'expired-callback': () => {
  //       //   console.log('createRecaptchaVerifier expired');
  //       // },
  //     },
  //     this.fireAuth
  //   );
  //   console.log('createRecaptchaVerifier: ', this.recaptchaVerifier);
  // }
}
