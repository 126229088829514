import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from 'libs/user/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthAdminGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.authGuardUser$.pipe(
      map((user) => {
        if ((!user || !user.isAdmin === true) && !state.url.includes('login')) {
          this.router.navigateByUrl('login');
          return false;
        }

        if (user && user.isAdmin === true && state.url.includes('login')) {
          this.router.navigateByUrl('/');
          return false;
        }

        return true;
      })
    );
  }
}
