import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  from,
  lastValueFrom,
  of,
} from 'rxjs';
import { User } from './user.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { AuthService } from 'libs/auth/auth.service';
import {
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeLast,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { PushNotificationService } from 'apps/craf2s-client-app/src/app/services/push-notification/push-notification.service';
import { Training } from 'libs/training/training.model';
import { WhereQuery } from 'libs/api/api.provider';
import { tr } from 'date-fns/locale';

type IsUserRegisteredResponse = {
  isUserKnown: boolean;
  isUserRegistered: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User | null> =
    new BehaviorSubject(null);
  currentUser$: Observable<User | null> =
    this.currentUserSubject.asObservable();
  authGuardUser$: Observable<User | null>;

  private isListeningToUserIds: string[] = [];
  private usersSubject: BehaviorSubject<User[]>[] = [];
  users$: Observable<User[]>[] = [];

  private isListeningToAllUsers = false;
  private allUsersSubject: BehaviorSubject<User[]> = new BehaviorSubject<
    User[]
  >([]);
  allUsers$: Observable<User[]> = this.allUsersSubject.asObservable();

  private isListeningToAdminUsers = false;
  private adminUsersSubject: BehaviorSubject<User[]> = new BehaviorSubject<
    User[]
  >([]);
  adminUsers$: Observable<User[]> = this.adminUsersSubject.asObservable();

  private isListeningToNetworkAdminUsers = false;
  private networkAdminUsersSubject: BehaviorSubject<User[]> =
    new BehaviorSubject<User[]>([]);
  networkAdminUsers$: Observable<User[]> =
    this.networkAdminUsersSubject.asObservable();

  private isListeningToAlumni = false;
  private alumniSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(
    []
  );
  alumni$: Observable<User[]> = this.alumniSubject.asObservable();

  private currentFcmToken: string;
  private currentTrainingCenterId: string;
  private currentNetworkId: string;

  constructor(
    private auth: AuthService,
    private apiService: FirebaseApiProvider,
    private unsubscriberService: UnsubscriberService,
    private pushNotificationService: PushNotificationService,
    private router: Router
  ) {
    this.authGuardUser$ = this.auth.uid$.pipe(
      switchMap((uid) => (uid ? this.getAuthGuardUser(uid) : of(null)))
    );
  }

  getAuthGuardUser(uid: string): Observable<User | null> {
    const currentUser = this.getCurrentUser();
    if (currentUser?.authId === uid) {
      console.log('getAuthGuardUser FROM CACHE:', currentUser);
      return this.currentUser$;
    }

    console.log('getAuthGuardUser uid:', uid);
    // It runs only when the user logs in or on page refresh
    return from(
      this.apiService.fetchAll<User>('users', User.fromObject, [
        {
          fieldPath: 'authId',
          condition: '==',
          value: uid,
        },
      ])
    ).pipe(
      map((users) => users[0] ?? null),
      tap((user) => {
        console.log('getAuthGuardUser FETCH:', user);
        if (user?.id) {
          this.currentUserSubject.next(user);
          this.listenToCurrentUser(user.id);
        }
      }),
      finalize(() => console.log('getAuthGuardUser FETCH finalized.'))
    );
  }

  listenToCurrentUser(userId: string) {
    const userSubscriber = this.apiService
      .fetchOne<User>('users', userId, User.fromObject)
      .pipe(finalize(() => console.log('listenToCurrentUser finalized.')))
      .subscribe((user) => {
        const currentUser = this.getCurrentUser();
        if (JSON.stringify(currentUser) !== JSON.stringify(user) && user?.id) {
          console.log('listenToCurrentUser currentUserSubject Next:', user);
          this.currentUserSubject.next(user);
        }
      });
    this.unsubscriberService.add(
      'UserService:listenToCurrentUser',
      userSubscriber
    );
  }

  isUserRegistered(
    email: string,
    mustBeAdmin = false
  ): Promise<IsUserRegisteredResponse> {
    return firstValueFrom(
      this.apiService.callFunction<IsUserRegisteredResponse>(
        'isUserRegistered',
        {
          email,
          mustBeAdmin,
        }
      )
    );
  }

  getCurrentUser(): User | null {
    return this.currentUserSubject.getValue();
  }

  updateUser(user: User): Promise<void> {
    return this.apiService.update<User>(
      `users/${user.id}`,
      user,
      User.toObject
    );
  }

  async createOrUpdateUser(
    user: User,
    updateExistingUser = true
  ): Promise<string> {
    try {
      if (!user?.id || user?.id === '') {
        if (await this.userExists(user.email)) {
          throw { code: 'create/user-exists' };
        }

        user.id = await this.apiService.create<User>(
          'users',
          user,
          User.toObject
        );
      } else if (updateExistingUser) {
        await this.updateUser(user);
      }
      return user.id;
    } catch (e) {
      throw e;
    }
  }

  async userExists(email: string): Promise<User | null> {
    if (!email) {
      return null;
    }

    const users = await this.apiService.fetchAll<User>(
      'users',
      User.fromObject,
      [
        {
          fieldPath: 'email',
          condition: '==',
          value: email,
        },
      ]
    );
    return users?.length > 0 ? users[0] : null;
  }

  getUsers(userIds: string[]): Observable<User[]> {
    const userIdsTolistenTo = userIds.filter(
      (userId) => !this.isListeningToUserIds.includes(userId)
    );
    if (userIdsTolistenTo.length > 0 && !this.isListeningToAllUsers) {
      console.log('getUsers API CALL LISTENING:', userIdsTolistenTo);
      this.listenToUserChanges(userIdsTolistenTo);
    } else {
      console.log('getUsers FROM CACHE');
    }

    return this.allUsers$.pipe(
      map((users) => users.filter((user) => userIds.includes(user.id))),
      filter<User[]>((users) =>
        !this.isListeningToAllUsers
          ? users.length <= this.isListeningToUserIds.length
          : users.length > 0
      )
    );
  }

  private listenToUserChanges(userIds: string[]) {
    this.isListeningToUserIds = [...this.isListeningToUserIds, ...userIds];

    let inQueries = [];
    while (userIds.length > 0) {
      inQueries.push(userIds.splice(0, 30));
    }

    console.log('listenToUserChanges userIds:', inQueries);

    inQueries.forEach((inQuery: string[]) => {
      this.usersSubject.push(new BehaviorSubject<User[]>([]));

      const listenerIndex = this.usersSubject.length - 1;

      this.users$.push(this.usersSubject[listenerIndex].asObservable());

      const usersSubscribe = this.apiService
        .listenToChanges<User>('users', User.fromObject, [
          {
            fieldPath: 'id',
            condition: 'in',
            value: inQuery,
          },
        ])
        .pipe(
          finalize(() => {
            this.isListeningToUserIds = [];
            delete this.usersSubject[listenerIndex];
            delete this.users$[listenerIndex];
            this.allUsersSubject.next([]);
            console.log(`listenToUserChanges ${listenerIndex} finalized.`);
          })
        )
        .subscribe((users) => {
          console.log(`listenToUserChanges ${listenerIndex} NEXT:`, users);
          this.usersSubject[listenerIndex].next(users);
          this.allUsersSubject.next(
            this.usersSubject.reduce(
              (allusers, subject) => [...allusers, ...subject.getValue()],
              []
            )
          );
        });
      this.unsubscriberService.add(
        'UserService:listenToUserChanges',
        usersSubscribe
      );
    });
  }

  getAdminUsers(trainingCenterId?: string): Observable<User[]> {
    if (
      !this.isListeningToAdminUsers ||
      this.currentTrainingCenterId !== trainingCenterId
    ) {
      console.log('getAdminUsers API CALL LISTENING');
      this.listenToAdminUserChanges(trainingCenterId);
    } else {
      console.log('getAdminUsers FROM CACHE');
    }

    return this.adminUsers$;
    // return this.adminUsers$.pipe(filter<User[]>((users) => users.length > 0));
  }

  getNetworkAdminUsers(networkId: string): Observable<User[]> {
    if (
      !this.isListeningToNetworkAdminUsers ||
      this.currentNetworkId !== networkId
    ) {
      console.log('getNetworkAdminUsers API CALL LISTENING');
      this.listenToNetworkAdminUserChanges(networkId);
    } else {
      console.log('getNetworkAdminUsers FROM CACHE');
    }

    return this.networkAdminUsers$;
  }

  private listenToAdminUserChanges(trainingCenterId?: string) {
    if (this.adminUsersSubject.getValue().length > 0) {
      this.adminUsersSubject.next([]);
    }

    this.isListeningToAdminUsers = true;
    this.currentTrainingCenterId = trainingCenterId;

    const whereQ: WhereQuery[] = [
      {
        fieldPath: 'isAdmin',
        condition: '==',
        value: true,
      },
    ];
    if (trainingCenterId) {
      whereQ.push({
        fieldPath: 'trainingCenterId',
        condition: '==',
        value: trainingCenterId,
      });
    }

    const adminUsersSubscribe = this.apiService
      .listenToChanges<User>('users', User.fromObject, whereQ)
      .pipe(finalize(() => console.log(`listenToAdminUserChanges finalized.`)))
      .subscribe((users) => {
        console.log(`listenToAdminUserChanges NEXT:`, users);
        this.adminUsersSubject.next(users);
      });
    this.unsubscriberService.add(
      'UserService:listenToAdminUserChanges',
      adminUsersSubscribe
    );
  }

  private listenToNetworkAdminUserChanges(networkId: string) {
    if (this.networkAdminUsersSubject.getValue().length > 0) {
      this.networkAdminUsersSubject.next([]);
    }

    this.isListeningToNetworkAdminUsers = true;
    this.currentNetworkId = networkId;

    const whereQ: WhereQuery[] = [
      {
        fieldPath: 'isAdmin',
        condition: '==',
        value: true,
      },
      {
        fieldPath: 'networkId',
        condition: '==',
        value: networkId,
      },
    ];

    const networkAdminUsersSubscribe = this.apiService
      .listenToChanges<User>('users', User.fromObject, whereQ)
      .pipe(
        finalize(() =>
          console.log(`listenToNetworkAdminUserChanges finalized.`)
        )
      )
      .subscribe((users) => {
        console.log(`listenToNetworkAdminUserChanges NEXT:`, users);
        this.networkAdminUsersSubject.next(users);
      });
    this.unsubscriberService.add(
      'UserService:listenToNetworkAdminUserChanges',
      networkAdminUsersSubscribe
    );
  }

  getAllUsers(trainingCenterId?: string): Observable<User[]> {
    if (!this.isListeningToAllUsers) {
      this.isListeningToAllUsers = true;
      console.log('getAllUsers API CALL LISTENING');
      this.listenToAllUserChanges(trainingCenterId);
    } else {
      console.log('getAllUsers FROM CACHE');
    }

    return this.allUsers$.pipe(filter<User[]>((users) => users.length > 0));
  }

  private listenToAllUserChanges(trainingCenterId?: string) {
    if (this.allUsersSubject.getValue().length > 0) {
      this.allUsersSubject.next([]);
    }

    const whereQ: WhereQuery[] = [
      {
        fieldPath: 'trainings',
        condition: '!=',
        value: [],
      },
    ];
    if (trainingCenterId) {
      whereQ.push({
        fieldPath: 'trainingCenterId',
        condition: '==',
        value: trainingCenterId,
      });
    }

    const allUsersSubscribe = this.apiService
      .listenToChanges<User>('users', User.fromObject, whereQ)
      .pipe(
        finalize(() => {
          console.log(`listenToAllUserChanges finalized.`);
          this.isListeningToAllUsers = false;
        })
      )
      .subscribe((users) => {
        console.log(`listenToAllUserChanges NEXT:`, users);
        this.allUsersSubject.next(users);
      });
    this.unsubscriberService.add(
      'UserService:listenToAllUserChanges',
      allUsersSubscribe
    );
  }

  getAlumni(): Observable<User[]> {
    if (!this.isListeningToAlumni) {
      this.isListeningToAlumni = true;
      console.log('getAlumni API CALL LISTENING');
      this.listenToAlumniChanges();
    } else {
      console.log('getAlumni FROM CACHE');
    }

    return this.alumni$.pipe(filter<User[]>((users) => users.length > 0));
  }

  private listenToAlumniChanges() {
    if (this.alumniSubject.getValue().length > 0) {
      this.alumniSubject.next([]);
    }

    const alumniSubscribe = this.apiService
      .listenToChanges<User>('users', User.fromObject, [
        {
          fieldPath: 'role',
          condition: 'in',
          value: ['manager', 'alumni'],
        },
      ])
      .pipe(
        finalize(() => {
          console.log(`listenToAlumniChanges finalized.`);
          this.isListeningToAlumni = false;
        })
      )
      .subscribe((users) => {
        console.log(`listenToAlumniChanges NEXT:`, users);
        this.alumniSubject.next(users);
      });
    this.unsubscriberService.add(
      'UserService:listenToAlumniChanges',
      alumniSubscribe
    );
  }

  initFcm(user: User) {
    if (
      !this.unsubscriberService.subscribtions['UserService:fcmToken'] ||
      this.unsubscriberService.subscribtions['UserService:fcmToken'][
        this.unsubscriberService.subscribtions['UserService:fcmToken'].length -
          1
      ].closed
    ) {
      console.log('INIT FCM');
      this.pushNotificationService.init();
      const fcmSubscriber =
        this.pushNotificationService.currentFcmToken.subscribe((token) => {
          this.currentFcmToken = token;
          if (token && !user.fcmTokens.includes(token)) {
            console.log('INIT FCM UPDATE TOKEN: ', token);
            user.fcmTokens.push(token);
            this.updateUser(user);
          }
        });

      this.unsubscriberService.add('UserService:fcmToken', fcmSubscriber);
    }
  }

  async logout(isAdmin = false) {
    if (!isAdmin) {
      // Delete FCM Token and Unsubscribe listeners
      const user = this.getCurrentUser();
      const tokenIndex = user.fcmTokens.findIndex(
        (t) => t === this.currentFcmToken
      );
      user.fcmTokens.splice(tokenIndex, 1);
      await this.updateUser(user);
      await this.pushNotificationService.removeAllListenersAndDeleteToken();
    }

    await this.auth.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });

    this.unsubscriberService.unsubscribeAll();
    this.currentUserSubject.next(null);
  }

  async deleteOrUpdateTrainingUsers(
    users: User[],
    training?: Training
  ): Promise<void> {
    for (const user of users) {
      let countLinkedApprentices = 0;
      if (user.role === 'tutor') {
        countLinkedApprentices = training?.apprentices?.filter((a) =>
          a.tutors.includes(user.id)
        ).length;
      }

      const trainingIndex = user.trainings.findIndex(
        (id) => id === training?.id
      );
      if (trainingIndex !== -1 && countLinkedApprentices === 0) {
        user.trainings.splice(trainingIndex, 1);
      }

      if (!user.isRegistered && user.trainings.length === 0) {
        // console.log('delete user: ', user);
        await this.apiService.delete('users', user.id);
      } else {
        await this.apiService.update(`users/${user.id}`, user, User.toObject);
      }
    }
  }

  async fetchTrainingUsers(trainingId: string): Promise<User[]> {
    try {
      return this.apiService.fetchAll<User>('users', User.fromObject, [
        {
          fieldPath: 'trainings',
          condition: 'array-contains',
          value: trainingId,
        },
      ]);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async searchUsers(
    likeField: string,
    likeValue: string,
    role: string,
    trainingCenterId?: string,
    trainingId?: string,
    networkId?: string
  ): Promise<User[]> {
    console.log(
      'SEARCHUSERS:',
      likeField,
      likeValue,
      role,
      trainingCenterId,
      trainingId,
      networkId
    );

    const whereQ: any[] = [
      {
        fieldPath: 'role',
        condition: '==',
        value: role,
      },
    ];

    if (trainingCenterId) {
      whereQ.push({
        fieldPath: 'trainingCenterId',
        condition: '==',
        value: trainingCenterId,
      });
    }

    if (networkId) {
      whereQ.push({
        fieldPath: 'networkId',
        condition: '==',
        value: networkId,
      });
    }

    // Only Tutors can be associated multiple times
    // to the same training
    if (role !== 'tutor' && trainingId) {
      whereQ.push({
        fieldPath: 'trainings',
        condition: 'not-in',
        value: [[trainingId]],
      });
    }

    const users = await this.apiService.fetchAll<User>(
      'users',
      User.fromObject,
      whereQ
    );
    console.log('SEARCHUSERS:', users);
    return users.filter(
      (user) =>
        user[likeField] &&
        user[likeField].toLowerCase().includes(likeValue.toLocaleLowerCase())
    );
  }
}
