export const environment = {
  production: false,
  bundleId: 'fr.craf2s.app',
  url: 'https://test.app.link-up.fr/',
  useEmulators: false,
  limitMessagesCount: 20,
  firebase: {
    projectId: 'craf2s-dev',
    appId: '1:383651297268:web:029ece3d724b04229b6ba1',
    storageBucket: 'craf2s-dev.appspot.com',
    apiKey: 'AIzaSyC9DXPc5FcbDbfeSLVHjzY9ODHV_CBtRgw',
    authDomain: 'craf2s-dev.firebaseapp.com',
    messagingSenderId: '383651297268',
  },
  vapidKey:
    'BIhuTa7MwD75WYDA3nV7YATV1RGR42UFT_pe6vlc-sT7xSYpUrSPUYllTAHfC4UZhSVIJs_0Tc6KqQcm0cuLchE',
  dynamicLinkDomain: 'testcraf2s.page.link',
};
