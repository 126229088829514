/* eslint-disable no-underscore-dangle */
import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { deleteToken, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { environment } from 'apps/craf2s-client-app/src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from 'libs/global/global.service';
import { Router } from '@angular/router';
import { Badge } from '@capawesome/capacitor-badge';
// import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private _currentFcmToken = new BehaviorSubject<string>('');

  constructor(
    private zone: NgZone,
    private platform: Platform,
    // private backgroundMode: BackgroundMode,
    private router: Router,
    private globalService: GlobalService
  ) {}

  get currentFcmToken() {
    return this._currentFcmToken.asObservable();
  }

  get currentFcmTokenValue() {
    return this._currentFcmToken.value;
  }

  init() {
    try {
      if (this.platform.is('capacitor')) {
        // this.backgroundMode.enable();
        this.initCapacitorPushNotifications();
      } else {
        this.initWebPushNotifications();
      }
    } catch (e) {
      console.error(e);
    }
  }

  initCapacitorPushNotifications() {
    console.log('Initializing Push Notifications');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // PushNotifications.removeAllDeliveredNotifications();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('currentFcmToken: ', this._currentFcmToken.value);
      this._currentFcmToken.next(token.value);
      console.log('Push registration success, token: ' + token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.zone.run(() => {
          console.log('Push action performed: ' + JSON.stringify(notification));
          this.router.navigateByUrl(notification.notification.data.url);
          // this.router.navigateByUrl(notification.notification.data.url, { replaceUrl: true });
        });
      }
    );
  }

  initWebPushNotifications() {
    this._currentFcmToken.next(null);
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('initWebPushNotifications:', currentToken);
          this._currentFcmToken.next(currentToken);

          onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            this.globalService.messageToast(payload.notification.title, payload.notification.body, [
              {
                text: 'Voir',
                handler: () => {
                  this.router.navigateByUrl(payload.data.url, { replaceUrl: true });
                },
              },
            ]);
          });
        } else {
          // Show permission request UI
          console.log('Requesting permission...');
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
            }
          });
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  }

  async setBadgeNumber(count: number) {
    if (this.platform.is('capacitor')) {
      console.log('SET BADGE NUMBER: ', count);
      await Badge.set({ count });
    }
  }

  // async decreaseBadgeNumber(count = 1) {
  //   const badgeNumber = (await Badge.get()).count;
  //   const updatedBadgeNumber = badgeNumber - count;
  //   console.log('BADGE NUMBER FROM: ', badgeNumber);
  //   console.log('BADGE NUMBER TO: ', updatedBadgeNumber);
  //   await Badge.set({ count: updatedBadgeNumber > 0 ? updatedBadgeNumber : 0 });
  // }

  // async increaseBadgeNumber(count = 1) {
  //   const badgeNumber = (await Badge.get()).count;
  //   await Badge.set({ count: badgeNumber + count });
  // }

  async removeAllListenersAndDeleteToken() {
    console.log('Push Notification delete token !!');
    if (this.platform.is('capacitor')) {
      await PushNotifications.removeAllListeners();
    } else {
      try {
        const messaging = getMessaging();
        await deleteToken(messaging);
      } catch (e) {
        console.log('Unable to delete fcm token:', e);
      }
    }
  }
}
