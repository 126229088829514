import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthAdminGuard } from 'libs/auth/auth-admin.guard';
import { SuperAdminGuard } from 'libs/training-center/super-admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'networks/:id',
    loadChildren: () =>
      import('./pages/networks/networks.module').then(
        (m) => m.NetworksPageModule
      ),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'training-centers',
    loadChildren: () =>
      import('./pages/training-centers/training-centers.module').then(
        (m) => m.TrainingCentersPageModule
      ),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'training-centers/:id',
    loadChildren: () =>
      import('./pages/training-centers/training-centers.module').then(
        (m) => m.TrainingCentersPageModule
      ),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AuthAdminGuard],
  },
  {
    // HACK TO AVOID BLANK PAGE WHEN ADMIN FIRST LOGIN
    path: 'avatar',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'trainings',
    loadChildren: () =>
      import('./pages/trainings/trainings.module').then(
        (m) => m.TrainingsPageModule
      ),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'trainings/:id',
    loadChildren: () =>
      import('./pages/trainings/trainings.module').then(
        (m) => m.TrainingsPageModule
      ),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'questionnaires/:type/:userRole',
    loadChildren: () =>
      import('./pages/questionnaires/questionnaires.module').then(
        (m) => m.QuestionnairePageModule
      ),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'questionnaires/:type/:userRole/:id',
    loadChildren: () =>
      import('./pages/questionnaires/questionnaires.module').then(
        (m) => m.QuestionnairePageModule
      ),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersPageModule),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AuthAdminGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
