import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TrainingCenterService } from './training-center.service';
import { UserService } from 'libs/user/user.service';
import { User } from 'libs/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private trainingCenterService: TrainingCenterService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.authGuardUser$.pipe(
      tap((user) => console.log('SUPER ADMIN GUARD USER: ', user)),
      switchMap((user: User) =>
        this.trainingCenterService.getSuperAdminGuardTrainingCenter(
          user.trainingCenterId
        )
      ),
      map((currentUserTrainingCenter) => {
        console.log('SUPER ADMIN GUARD: ', currentUserTrainingCenter);

        if (
          !currentUserTrainingCenter ||
          !currentUserTrainingCenter.isSuperAdmin === true
        ) {
          return false;
        }
      })
    );
  }
}
