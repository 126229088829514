import { Document } from 'libs/document/document.model';

export enum displayRole {
  apprentice = 'Stagiaire',
  manager = 'Coordonnateur',
  tutor = 'Tuteur',
  alumni = 'Alumni',
}

export class User {
  public static EMPTY_USER = new User('', '', '', '', '', false, true, [], []);

  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public role: string,
    public isAdmin: boolean,
    public isRegistered: boolean,
    public trainings: string[],
    public fcmTokens: string[],
    public trainingCenterId?: string,
    public networkId?: string,
    public id?: string,
    public authId?: string,
    public status?: string,
    public avatar?: string,
    public companyName?: string,
    public companyAddress?: string,
    public results?: any[],
    public evaluations?: any[],
    public documents?: Document[],
    public tutors?: User[],
    public apprentices?: User[],
    public evaluationsByQuestionnaireId?: { [questionnaireId: string]: any }
  ) {}

  static fromObject(data: any): User {
    // Add id to documents
    const documents = data?.documents
      ? data?.documents.map((doc: Document, index: number) => {
          doc.id = index.toString();
          return doc;
        })
      : [];
    const user = new User(
      data?.firstName,
      data?.lastName,
      data?.email,
      data?.phone?.replace(/\s+/g, ''),
      data?.role,
      data?.isAdmin ? data?.isAdmin : false,
      data?.isRegistered ? data?.isRegistered : false,
      data?.trainings,
      data?.fcmTokens ? data?.fcmTokens : [],
      data?.trainingCenterId,
      data?.networkId,
      data?.id,
      data?.authId,
      data?.status,
      data?.avatar,
      data?.companyName,
      data?.companyAddress,
      data?.results ? data?.results : [],
      data?.evaluations ? data?.evaluations : [],
      documents
    );

    if (data?.evaluations) {
      user.evaluationsByQuestionnaireId = data?.evaluations.reduce(
        (evaluationsByQuestionnaireId, evaluation) => {
          evaluationsByQuestionnaireId[evaluation.questionnaireId] = evaluation;
          return evaluationsByQuestionnaireId;
        },
        {}
      );
    }

    return user;
  }

  static toObject(user: User): any {
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone.toString().replace(/\s+/g, ''),
      role: user.role,
      isAdmin: user.isAdmin ? true : false,
      isRegistered: user.isRegistered ? true : false,
      trainings: user.trainings,
      fcmTokens: user.fcmTokens ?? [],
      avatar: user.avatar ?? '',
    };

    if (user.role === 'apprentice') {
      data['status'] = user.status ?? 'Stagiaire';
    }

    if (user.role === 'tutor') {
      data['companyName'] = user.companyName;
      data['companyAddress'] = user.companyAddress;
    }

    if (user.results) {
      data['results'] = user.results;
    }

    if (user.evaluations) {
      data['evaluations'] = user.evaluations;
    }

    if (user.authId) {
      data['authId'] = user.authId;
    }

    if (user.trainingCenterId) {
      data['trainingCenterId'] = user.trainingCenterId;
    }

    if (user.networkId) {
      data['networkId'] = user.networkId;
    }

    if (user.documents) {
      data['documents'] = user.documents;
    }

    return data;
  }

  get displayRole() {
    return displayRole[this.role];
  }

  static getDisplayFields(role: string) {
    const displayFields: any[] = [
      { name: 'lastName', header: 'Nom', size: 2 },
      { name: 'firstName', header: 'Prénom', size: 2 },
      { name: 'email', header: 'E-mail', size: 4 },
      { name: 'phone', header: 'Tel.', size: 2 },
    ];

    if (role === 'apprentice') {
      displayFields.push({ name: 'status', header: 'Statut', size: 3 });
    }

    if (role === 'tutor') {
      displayFields.push({ name: 'companyName', header: 'Structure', size: 3 });
    }

    return displayFields;
  }

  static getImportFields(role: string) {
    let importFields: any[] = ['lastName', 'firstName', 'email', 'phone'];

    if (role === 'apprentice') {
      importFields = [
        ...importFields,
        'status',
        'tutorLastName',
        'tutorFirstName',
        'tutorEmail',
        'tutorPhone',
        'tutorCompanyName',
        'tutorCompanyAddress',
      ];
    }

    return importFields;
  }
}
