import { format } from 'date-fns';
import { da, fr } from 'date-fns/locale';

export type Subscription = {
  id?: string;
  amount: number;
  expirationDate: string;
  limits: {
    maxApprentices: number;
  };
};

type currentSubscription = Subscription & {
  apprenticesCount: number;
  limitColor: string;
};

export class TrainingCenter {
  constructor(
    public name: string,
    public address: string,
    public zipCode: string,
    public city: string,
    public subscriptions: Subscription[],
    public apprenticesCount: number,
    public isSuperAdmin?: boolean,
    public networkId?: string,
    public id?: string,
    public currentSubscription?: currentSubscription,
    public hasNotReachedMaxApprentices?: boolean
  ) {}

  static fromObject(data: any): TrainingCenter {
    const trainingCenter = new TrainingCenter(
      data?.name,
      data?.address,
      data?.zipCode,
      data?.city,
      data?.subscriptions ?? [],
      data?.apprenticesCount ?? 0,
      data?.isSuperAdmin ?? false,
      data?.networkId,
      data?.id
    );

    if (data?.subscriptions?.length > 0) {
      trainingCenter.subscriptions.forEach(
        (subscription: Subscription, index: number) =>
          (subscription.id = `${index}`)
      );
      const lastSubscription = data.subscriptions.reduce(
        (lastSubscription: Subscription, subscription: Subscription) => {
          if (subscription.expirationDate > lastSubscription.expirationDate) {
            return subscription;
          }
          return lastSubscription;
        },
        data.subscriptions[0]
      );
      trainingCenter.currentSubscription = {
        ...lastSubscription,
        apprenticesCount: data.apprenticesCount,
      };
      trainingCenter.currentSubscription.limitColor =
        trainingCenter.currentSubscription.apprenticesCount >=
        trainingCenter.currentSubscription.limits.maxApprentices
          ? 'danger'
          : trainingCenter.currentSubscription.apprenticesCount >=
            Math.floor(
              trainingCenter.currentSubscription.limits.maxApprentices * 0.9
            )
          ? 'warning'
          : 'success';
    }

    return trainingCenter;
  }

  static toObject(trainingCenter: TrainingCenter): any {
    const data = {
      name: trainingCenter.name,
      address: trainingCenter.address,
      zipCode: trainingCenter.zipCode,
      city: trainingCenter.city,
    };

    if (trainingCenter.subscriptions) {
      data['subscriptions'] = trainingCenter.subscriptions;
    }

    if (trainingCenter.apprenticesCount !== undefined) {
      data['apprenticesCount'] = trainingCenter.apprenticesCount;
    }

    if (trainingCenter.networkId !== undefined) {
      data['networkId'] = trainingCenter.networkId;
    }

    return data;
  }

  static getDisplayFields(): any[] {
    const displayFields: any[] = [
      { name: 'name', header: 'Nom', size: 3 },
      { name: 'city', header: 'Ville', size: 2 },
      {
        name: 'currentSubscription',
        header: `Expiration abonnement`,
        transform: (currentSubscription: currentSubscription) =>
          format(new Date(currentSubscription.expirationDate), 'dd MMMM yyyy', {
            locale: fr,
          }),
        size: 2,
      },
      {
        name: 'currentSubscription',
        header: 'Usage',
        transform: (currentSubscription: currentSubscription) =>
          `${currentSubscription.apprenticesCount} stagiaires / ${currentSubscription.limits.maxApprentices} max`,
        size: 2,
      },
    ];

    return displayFields;
  }

  static getSubscriptionDisplayFields(): any[] {
    return [
      {
        name: 'expirationDate',
        header: `Date d'expiration`,
        transform: (val: string) =>
          format(new Date(val), 'dd MMMM yyyy', {
            locale: fr,
          }),
        size: 2,
      },
      {
        name: 'limits',
        header: 'Limite',
        transform: (limits: { maxApprentices: number }) =>
          `${limits.maxApprentices} stagiaires max`,
        size: 2,
      },
      {
        name: 'amount',
        header: `Montant`,
        transform: (amount: number) => `${amount} €`,
        size: 2,
      },
    ];
  }
}
